<template>
  <div>
    <f2f-alert
      :message="getNotification.message"
      :display="getNotification.display"
      @hide="hideNotification"
    />
    <div class="f2f-management">
      <div class="f2f-management__container">
        <b-row>
          <b-col cols="12">
            <div class="f2f-management__head">
              <div class="ml-3">
                <div class="management-head__title">
                  Classes > {{ (getClass || {}).title }} >
                </div>
                <div class="management-head__section">Attendace</div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4 m-0">
          <b-col cols="12">
            <AttendanceList />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import AttendanceList from "@/components/classes/management/AttendanceList.vue";
import { mapGetters } from "vuex";

export default {
  components: { AttendanceList },
  computed: {
    ...mapGetters(["getClass", "getNotification"])
  },
  mounted() {
    this.loadClassAndSession();
  },
  methods: {
    async loadClassAndSession() {
      this.$store.dispatch("getClassDetails", {
        courseId: this.$route.params.id,
        classId: this.$route.params.classId
      });
      const sessionsRes = await this.$store.dispatch("getSessionsForClass", {
        course_id: this.$route.params.id,
        class_id: this.$route.params.classId
      });
      this.sessions = sessionsRes && sessionsRes.data ? sessionsRes.data : [];
    }
  }
};
</script>
<style lang="scss" scoped>
.bread-link {
  line-height: 24px;
  cursor: pointer;
  color: #4c4c4c;
}
.f2f-management {
  .f2f-management__container {
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;
    padding-bottom: 60px;
    .f2f-management__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0rem;
      border-bottom: 0.5px solid #e8e8e8;

      .management-head__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #4c4c4c;
      }

      .management-head__section {
        font-weight: bold;
        font-size: 38px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #333333;
      }
    }
  }
}
</style>
