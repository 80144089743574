<template>
  <div class="management-list">
    <div class="loader" v-if="isLoadingClasses">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-if="!isLoadingClasses">
      <div
        v-if="(getAllSessionAttendance || []).length == 0"
        class="no-record-holder"
      >
        <div>
          <img src="@/assets/wrappers/norecord.svg" />
          <p>No Attendance found!</p>
        </div>
      </div>
      <div v-if="(getAllSessionAttendance || []).length > 0">
        <b-container fluid>
          <b-row class="mt-3 mb-4">
            <b-col cols="4">
              <f2f-input
                id="icon"
                placeholder="Find a learner"
                v-model="searchTerm"
            /></b-col>

            <b-col>
              <div class="float-right">
                <b-dropdown
                  v-if="disableButton == !isDisabled"
                  :disabled="!isDisabled"
                  variant="outline-primary"
                  text="BULK ACTION"
                >
                  <b-dropdown-item @click="markBulkAttendance(true)"
                    >Present</b-dropdown-item
                  >
                  <b-dropdown-item @click="markBulkAttendance(false)"
                    >Absent</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-table
          small
          borderless
          responsive
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          v-if="!isLoadingClasses"
          class="p-3"
          style="min-height: 225px"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              :id="data.item.id"
              v-model="user_ids"
              :value="data.item.id"
            >
            </b-form-checkbox>
          </template>
          <template #cell(full_name)="data">
            <p class="learner-text-bold">
              {{ data.item.full_name }}
            </p>
          </template>
          <template #cell(email)="data">
            <p class="learner-text">{{ data.item.email }}</p>
          </template>
          <template #cell(enrollment_status)="data">
            <p class="learner-text">{{ data.item.enrollment_status }}</p>
          </template>
          <template #cell(enrollment_date)="data">
            <p class="learner-text">
              {{ data.item.enrollment_date }}
            </p>
          </template>
          <template #cell(options)="data">
            <b-button
              :id="'popover' + data.item.id"
              class="b-transparent float-right"
            >
              <img src="@/assets/three-dots.svg" alt="" />
            </b-button>
            <b-popover
              :target="'popover' + data.item.id"
              triggers="focus"
              placement="top"
            >
              <div class="edit-option">
                <div @click="markAttendance(data.item.id, true)">Present</div>
                <div class="separator"></div>
                <div @click="markAttendance(data.item.id, false)">Absent</div>
              </div>
            </b-popover>
          </template>
        </b-table>

        <f2f-pagination
          class="mr-1 mb-2"
          :total-rows="rows"
          v-mode="currentPage"
          @page="onPage"
        />
      </div>
    </div>
    <f2f-modal
      :display="onError"
      @hide="onError = false"
      type="danger"
      :title="errortitle"
      :body="error"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    classId: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      errortitle: "Unable to mark attendance",
      error: "",
      onError: false,
      showmodal: false,
      perPage: 10,
      currentPage: 1,
      detailsShowing: "",
      currentSessionId: -1,
      confirmCancel: false,
      unableCancel: false,
      cancelError: false,
      user_ids: [],
      actionType: "single",
      searchTerm: "",
      disableButton: false,
      currentSessionDate: "",
      fields: [
        {
          key: "show_details",
          label: ""
        },
        {
          key: "full_name",
          label: "Learner name",
          thStyle: { width: "15%" }
        },
        {
          key: "email",
          label: "Email",
          thStyle: { width: "25%" }
        },
        {
          key: "enrollment_status",
          label: "Attendace record",
          thStyle: { width: "35%" }
        },
        {
          key: "enrollment_date",
          label: "Enrollment date"
        },
        {
          key: "options",
          label: ""
        }
      ]
    };
  },
  components: {},
  computed: {
    ...mapGetters([
      "getAllSessionAttendance",
      "isLoadingClasses",
      "getIsInstructor"
    ]),

    items() {
      return (this.getAllSessionAttendance || [])
        .filter((learner) => {
          if (this.searchTerm) {
            return learner.full_name
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase());
          } else {
            return true;
          }
        })
        .map((cls) => {
          let enrollment_status = "Not marked";
          if ("has_attended" in cls && cls.has_attended != null) {
            enrollment_status = cls.has_attended ? "Present" : "Absent";
          }
          return {
            full_name: cls.full_name,
            email: cls.email,
            enrollment_status: enrollment_status,
            enrollment_date: cls.enrollment_date
              ? this.formatDateByPattern(
                  new Date(cls.enrollment_date),
                  "DD/MM/YYYY"
                )
              : "",
            id: cls.id
          };
        });
    },
    rows() {
      return this.items.length;
    },
    isDisabled() {
      return this.user_ids[0] > 0;
    }
  },
  async mounted() {
    const session_id = this.$route.params.session_id;
    this.getAttendance();
    const sessionsRes = await this.$store.dispatch("getSessionsForClass", {
      course_id: this.$route.params.id,
      class_id: this.$route.params.classId
    });
    const sessions = sessionsRes && sessionsRes.data ? sessionsRes.data : [];
    const currentSession = sessions.find((s) => +s.id === +session_id);
    this.currentSessionDate = this.formatDateByPattern(
      currentSession.date_at,
      "YYYY-MM-DD"
    );
  },
  methods: {
    getAttendance() {
      const session_id = this.$route.params.session_id;
      this.$store.dispatch("getSessionAttendance", {
        course_id: this.$route.params.id,
        class_id: this.$route.params.classId,
        session_id
      });
    },
    async postAttendance(payload) {
      const toPost = {
        course_id: this.$route.params.id,
        class_id: this.$route.params.classId,
        session_id: this.$route.params.session_id,
        payload: payload
      };
      try {
        await this.$store.dispatch("addMarkAttendance", toPost);
        this.getAttendance();
        this.$store.dispatch("showNotification", {
          message: "Attendance marked successfully"
        });
      } catch (err) {
        this.error =
          err?.response?.data?.error ||
          "Unable to mark attendance | Some unknown error occured";
        this.onError = true;
      }
    },
    async markBulkAttendance(has_attended) {
      const payload = this.user_ids.map((userId) => {
        return {
          date_at: this.currentSessionDate,
          has_attended: has_attended,
          user: userId,
          session: this.$route.params.session_id
        };
      });
      this.postAttendance(payload);
    },
    async markAttendance(userId, has_attended) {
      this.postAttendance([
        {
          date_at: this.currentSessionDate,
          has_attended: has_attended,
          user: userId,
          session: this.$route.params.session_id
        }
      ]);
    },
    onPage(page) {
      this.perPage = page.perPage;
      this.currentPage = page.currentPage;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;
  .form-space {
    min-width: auto;
  }
}

.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;
}
.no-record-holder {
  height: 60vh;
  min-height: 300px;
  border: 1px solid #b2b2b2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  img {
    width: 80px;
    margin-bottom: 1rem;
  }
}
.loader {
  margin-top: 20vh;
  text-align: center;
}
::v-deep {
  thead {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #a0a4a8;
  }
}
::v-deep tbody {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
::v-deep .nav-tabs {
  font-size: 14px;
}
.management-list {
  min-height: 300px;
  border: 1px solid #b2b2b2;
  background: white;
}
input.b-form-input {
  border-radius: 10px;
}
.learner-text-bold {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #25282b;
}
.learner-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #52575c;
}
.learner-text:hover {
  color: #0043ac;
  cursor: pointer;
}

.dropdown {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0043ac;
}
::v-deep .dropdown-item {
  padding-bottom: 8px;
  margin-top: 8px;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #52575c;
}
::v-deep .btn-outline-primary {
  color: #0043ac;
  border-color: #0043ac;
}
::v-deep .btn-outline-primary:hover {
  background: none;
  color: #0043ac;
}
::v-deep .dropdown-item:hover,
.dropdown-item:focus {
  color: #0043ac;
}
::v-deep .btn-outline-primary:not(:disabled):not(.disabled):active {
  background: none;
  color: #0043ac;
}
::v-deep .dropdown-item:hover,
.dropdown-item:focus {
  background: #dceaff;
}
.b-transparent {
  background: transparent;
  border: none;
  :focus {
    background: transparent;
  }
  img {
    position: relative;
    bottom: 14px;
  }
}
.btn-secondary:hover {
  background: none;
}
.btn-secondary:focus {
  background: none;
}
.edit-option {
  background: #ffffff;
  border-radius: 4px;
  border: none;

  .separator {
    border: 0.5px solid #e8e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
  }

  div {
    color: #52575c;
    font-size: 16px;
  }

  div:hover {
    color: #0043ac;
    cursor: pointer;
  }
}

.pagination-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #757575;
}

element.style {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 38px, 0px);
}
// .ul .dropdown-menu.show {
//     display: block !important;
//     margin-top: 10px !important;
//     // top: 20px;
//     transform: translate3d(0px, 38px, 0px);
// }
</style>
